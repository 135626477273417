<template>
  <div>
    <h1 class="mb-0 login--whats-new">What's new</h1>
    <h3 class="mt-3 mb-3 login--join-today">Join now.</h3>
    <b-button variant="create-account mt-4 w-100">Sign up for free trial</b-button>
    <p class="mt-2 mb-4 login-policy-text">
      By signing up, you agree to the <b-link>Terms of Service</b-link> and <b-link>Privacy Policy</b-link>,
      including <b-link>Cookie use</b-link>.
    </p>
    <h4 class="mt-4 text-center">Already have an account?</h4>
    <form class="mt-4" @submit.prevent="onSignInHandler">
      <div class="form-group">
        <input
          type="email"
          v-model="loginData.email"
          class="form-control mb-0"
          id="email"
          placeholder="Enter email"
        >
        <div class="invalid-feedback d-block" v-if="!$v.loginData.email.required && formSubmitted">Please enter email
        </div>
        <div class="invalid-feedback d-block" v-if="!$v.loginData.email.email && formSubmitted">Please enter valid
          email
        </div>
      </div>
      <div class="form-group position-relative">
        <input
          :type="showPassword ? 'text' : 'password'"
          v-model="loginData.password"
          class="form-control mb-0"
          id="password"
          placeholder="Password"
        >
        <div class="password-show-button" @click="showPassword = !showPassword">
          <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-eye" viewBox="0 0 16 16">
            <path
              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-eye-slash" viewBox="0 0 16 16">
            <path
              d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path
              d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path
              d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
        </div>
        <div class="invalid-feedback d-block" v-if="!$v.loginData.password.required && formSubmitted">
          Please enter password
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <b-button class="w-100" type="submit" variant="transparent">Login</b-button>
      </div>
      <div class="mt-3 forgot-password">
        <b-link>Forgot password?</b-link>
      </div>
    </form>
  </div>
</template>

<script>
// import { socialvue } from '@/config/pluginInit'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import * as authActions from '@/store/modules/auth/types/actions'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'

export default {
  name: 'Login',
  validations: {
    loginData: {
      email: { required, email },
      password: { required }
    }
  },
  mounted () {
    // socialvue.index()
  },
  data () {
    return {
      formSubmitted: false,
      formValid: false,
      showPassword: false,
      loginData: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('auth', {
      signIn: authActions.SIGN_IN,
      fetchLoggedUser: authActions.FETCH_LOGGED_USER
    }),
    ...mapActions('systemNotification', {
      setErrorNotification: systemNotificationActions.SET_ERROR_NOTIFICATION
    }),
    async onSignInHandler () {
      try {
        this.formSubmitted = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          throw new Error('Please enter correct credentials')
        }
        await this.signIn(this.loginData)
        await this.fetchLoggedUser()
        this.$router.push({ name: 'social.feed' })
      } catch (error) {
        this.setErrorNotification(error.message)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.login--whats-new {
  font-size: 60px !important;
  font-weight: 900 !important;
}

.login--join-today {
  font-size: 40px !important;
  font-weight: 800 !important;
}

.btn-create-account {
  background-color: rgb(0, 150, 221);
  color: rgb(255, 255, 255) !important;
  min-height: 100px;
  border-radius: 25px !important;
  font-size: 30px !important;
}

.login-policy-text {
  font-size: 12px;

  a {
    color: rgb(0, 150, 221) !important;
  }
}

.forgot-password {
  a {
    font-weight: 600;
    color: rgb(0, 150, 221) !important;
  }
}

.form-group {
  input {
    &.form-control {
      background-color: rgb(238, 239, 240) !important;
      border-radius: 15px !important;
      height: 69px !important;
    }
  }
}
</style>
